import React, { Component } from 'react';

class ContentBg extends Component {
    state = {  }
    render() { 
        return ( 
            <div></div>
         );
    }
}
 
export default ContentBg;